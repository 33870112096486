.dropdown-menu {
    position: relative;
    background-color: #3f3f3f;
    font-size: 16px;
    box-shadow: 0 5px 25px rgb(0 0 0 / 20%);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius: 2px;
    border: none;
    color: #fff;
    padding: 10px 30px 10px 10px;
    outline: none;
}

.dropdown-box {
    margin-left: 10%;
}

@media (max-width: 391px) {
    .dropdown-menu {
        margin-left: 7%;
    }
}
