.calendar__btn__container {
    display: flex;
    flex-direction: row;
}

.rdp-button {
    color: #fff;
}

.rdp-button:hover {
    color: #000000;
}

.rdp-caption_label {
    color: #fff;
}

.rdp-head_cell {
    color: #fff;
}

.rdp-tfoot {
    color: #fff;
}

.range_reset,
.range_submit {
    background-color: rgb(43, 220, 226);
    border: none;
    text-align: center;
    width: 150px;
    height: 40px;
    position: relative;
    font-weight: 100;
    font-size: 18px;
    color: #fff;
    border-radius: 7px;
    line-height: 40px;
    cursor: pointer;
    margin: 8px;
}
