#main {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.left,
.right {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 391px) {
    #main {
        flex-direction: column;
    }
    .right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
