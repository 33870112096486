.navBar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding: 2%;
}
img {
    width: 100px;
    height: 100px;
}
.text {
    margin: auto;
    color: white;
}

.signIn,
.admin_panel {
    background: #696a6c;
    border-color: transparent;
    width: 100px;
    height: 35px;
    outline-color: transparent;
    border-radius: 5px;
    color: #fff;
    font-size: 21px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
}

.admin_panel {
    width: 10rem;
}
