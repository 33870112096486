body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

body::-webkit-scrollbar {
    width: 0;
    display: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

body::-webkit-scrollbar-track {
    display: transparent;
}

body::-webkit-scrollbar-thumb {
    background: transparent;
}

body::-webkit-scrollbar-thumb:hover {
    background: transparent;
}
