.LoginContainer {
    position: relative;
    width: 100%;
    text-align: center;
}

.RangeExample {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 230px;
    margin: 0 auto;
}

.nice-select {
    text-align: center;
}

.calendar__btn__container {
    display: flex;
    justify-content: space-around;
}

.link {
    background-color: #34ebe8;
    border: none;
    text-align: center;
    height: 40px;
    position: relative;
    font-weight: 100;
    width: 100%;
    font-size: 18px;
    color: #fff;
    border-radius: 7px;
    line-height: 40px;
    cursor: pointer;
    margin: 8px;
}
