.appointments__boxes {
    padding: 10px;
}

.appointments__logoRoom {
    display: flex;
}

#total-players,
#total-teams {
    color: #34ebe8;
}

.appointments__logoRoom img {
    object-fit: contain;
}

@media (min-width: 21rem) {
    .appointments__boxes {
        width: 90%;
    }
}

.appointments__content {
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    display: flex;
    /* flex-direction: column; */
    overflow: hidden;
    position: relative;
    justify-content: space-evenly;
    color: #696969;
    border: solid 1px white;
    flex-wrap: wrap;
}

.appointments__players,
.appointments__teams,
.appointments__bookings {
    display: flex;
    width: auto;
}

.appointments__players p,
.appointments__teams p,
.appointments__test p {
    color: rgb(255, 255, 255);
    font-weight: 900;
    text-transform: uppercase;
    align-self: center;
}

@media (min-width: 1140px) {
    .appointments__bookings p {
        align-self: center;
    }
}

.appointments__bookings p {
    color: white;
    font-weight: 900;
    text-transform: uppercase;
}

.appointments__players p span,
.appointments__teams p span,
.appointments__total p span,
.appointments__bookings_container span {
    color: #da1a35;
}

.appointments__boxes {
    margin: 0 auto;
}

.appointments__bookings_container {
    display: flex;
    flex-direction: column;
    padding: 15px;
}
.appointments__total p {
    color: white;
    font-weight: 900;
    text-transform: uppercase;
    align-self: center;
}

@media (max-width: 390px) {
    .rdp-month:first-child {
        margin-left: 10%;
    }

    .appointments__container {
        margin-left: 5%;
    }
}
