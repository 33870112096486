.controlPanel_container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.management_menu {
    font-weight: 700;
    color: green;
    text-align: center;
}

.controlPanel_settings {
    width: 100%;
    margin: auto;
}

.operations_option {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 5rem;
    background-color: white;
    justify-content: center;
    font-weight: 700;
    border-radius: 5px;
}

.operations_option > h4 {
    margin-left: 1%;
}

.users_tab {
    background-color: white;
}

.password_cell {
    display: flex;
    align-items: center;
}

.show_hide_icon {
    cursor: pointer;
    display: flex;
    align-self: center;
    margin-left: 5%;
}

.password_text_input {
    display: flex;
    align-items: center;
}

.create_user_modal {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background-color: #da1a35;
    position: fixed;
    display: flex;
    right: 2rem;
    bottom: 2rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 900;
    z-index: 99;
    box-shadow: 5px 5px 5px 5px;
    border: 3px solid black;
    text-align: right;
}
