.loginForm__container {
    position: relative;
    width: 100%;
    margin-top: 10vh;
}

.loginForm__container img {
    max-height: 150px;
}

.loginForm__form__container {
    width: 50vw;
    margin: 0 auto;
    text-align: center;
    position: relative;
    width: 100%;
    justify-content: center;
    align-self: center;
    align-items: center;
}

.loginForm__btn__submit {
    background: #696a6c;
    border-color: transparent;
    width: 100px;
    height: 35px;
    outline-color: transparent;
    border-radius: 5px;
    color: #fff;
    background-color: #da1a35;
    font-size: 21px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
}

button:disabled {
    color: -internal-light-dark(
        rgba(16, 16, 16, 0.3),
        rgb(170, 170, 170)
    ) !important;
    background-color: -internal-light-dark(
        rgba(239, 239, 239, 0.3),
        rgba(19, 1, 1, 0.3)
    ) !important;
    border-color: -internal-light-dark(
        rgba(118, 118, 118, 0.3),
        rgba(195, 195, 195, 0.3)
    ) !important;
}

.loginForm__btn__submit:disabled {
    background-color: #696a6c;
}
