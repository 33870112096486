.accord_details {
    background-color: white;
}

.note-text {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    align-items: center;
}

.crud-buttons {
    margin-right: 30%;
}
